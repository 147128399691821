import styled from 'styled-components';
import { px2rem } from 'src/util';

export default styled.h2`
  color: #005370;
  font: 700 ${px2rem(50)} / 115% 'Univia Pro', 'Arial', 'sans-serif';
  letter-spacing: -0.045em;

  @media screen and (max-width: 997px) {
    font-size: 48px;
  }
`;
