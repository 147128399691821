import React from 'react';

import { Wrapper, Content, Addresses, Contact } from './styles';

export default function Footer() {
  return (
    <Wrapper id="footer">
      <div className="grid">
        <Content>
          <img src={require('assets/footer-logo.svg')} alt="Nexodata" />
          <Addresses>
            <h2>Endereços</h2>
            <div>
              <p>
                R. Gomes de Carvalho, 1629 - Cj. 101 <br /> São Paulo, SP
              </p>
              <p>
                Av. Angélica, 2529 <br /> São Paulo, SP
              </p>
              <div className="social-networks desktop">
                <a
                  href="https://www.youtube.com/channel/UCUvZwiM-VyEaZgoFP9q4UPQ"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="YouTube"
                >
                  <img src={require('assets/youtube.svg')} alt="YouTube" />
                </a>
                <a
                  href="https://www.linkedin.com/company/nexodata"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="LinkedIn"
                >
                  <img src={require('assets/linkedin.svg')} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </Addresses>
          <Contact>
            <h2>Contatos</h2>
            <p>
              Novos negócios:{' '}
              <a href="mailto:parceiros@nexodata.com.br">
                parceiros@nexodata.com.br
              </a>
            </p>
            <div>
              <p>
                Ajuda:{' '}
                <a href="mailto:ajuda@nexodata.com.br">ajuda@nexodata.com.br</a>
              </p>
            </div>
          </Contact>
          <div className="social-networks mobile">
            <a
              href="https://www.youtube.com/channel/UCUvZwiM-VyEaZgoFP9q4UPQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={require('assets/youtube.svg')} alt="YouTube" />
            </a>
            <a
              href="https://www.linkedin.com/company/nexodata"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={require('assets/linkedin.svg')} alt="LinkedIn" />
            </a>
          </div>
          <small>
            2020 Nexodata do Brasil. <span>Todos os direitos reservados.</span>
          </small>
        </Content>
      </div>
    </Wrapper>
  );
}
