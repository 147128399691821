// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-atrium-tsx": () => import("./../src/pages/atrium.tsx" /* webpackChunkName: "component---src-pages-atrium-tsx" */),
  "component---src-pages-farmacias-tsx": () => import("./../src/pages/farmacias.tsx" /* webpackChunkName: "component---src-pages-farmacias-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-receitas-tsx": () => import("./../src/pages/receitas.tsx" /* webpackChunkName: "component---src-pages-receitas-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */)
}

