import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Shape {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

interface Props {
  onChange?: (isVisible: boolean) => void;
  active?: boolean;
  partialVisibility?: boolean;
  offset?: Shape;
  minTopValue?: number;
  intervalCheck?: boolean;
  intervalDelay?: number;
  scrollCheck?: boolean;
  scrollDelay?: number;
  scrollThrottle?: number;
  resizeCheck?: boolean;
  resizeDelay?: number;
  resizeThrottle?: number;
  containment?: any;
  delayedCall?: boolean;
  children?:
    | React.ReactNode
    | ((args: {
        isVisible: boolean;
        visibilityRect?: Shape;
      }) => React.ReactNode);
}

export default function CustomVisibilitySensor({ children, ...props }: Props) {
  const [visible, setVisible] = useState(false);

  function handleChange(isVisible: boolean) {
    if (!visible && isVisible) {
      setVisible(isVisible);
    }
  }

  return (
    <VisibilitySensor
      active={!visible}
      delayedCall
      onChange={handleChange}
      {...props}
    >
      {children}
    </VisibilitySensor>
  );
}
