import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';

export default function InlineSVG({ className = undefined, src, ...props }) {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const request = axios.CancelToken.source();
    axios
      .get(src, { cancelToken: request.token })
      .then((result) => {
        let content = result.data.replace(
          /\n|<title>(.*?)<\/title>|<desc>(.*?)<\/desc>|<defs>(.*?)<\/defs>|<!--(.*?)-->/g,
          ''
        );
        setSvgContent(content);
      })
      .catch((error) => {});
    return () => {
      if (request) {
        request.cancel();
      }
    };
  }, [src]);

  return (
    <span
      {...props}
      className={classNames('inline-svg', className)}
      dangerouslySetInnerHTML={{
        __html: svgContent,
      }}
    ></span>
  );
}
