import React, { useState, HTMLProps } from 'react';
import { Wrapper, StyledText, StyledSingleInputForm } from './styles';
import { Title } from 'components';

interface OwnProps {
  buttonText?: any;
  inputId?: string;
  subtitle?: any;
  type: string;
  contactTitle?: any;
}

type Props = HTMLProps<HTMLDivElement> & OwnProps;

interface Values {
  email: string;
}

export default function Contact({
  buttonText,
  inputId,
  subtitle,
  type,
  contactTitle,
  ...props
}: Props) {
  return (
    <Wrapper {...props}>
      <Title>
        {contactTitle || 'Mal podemos esperar para construir algo juntos!'}
      </Title>
      <StyledText>
        {subtitle || 'Nosso time de especialistas pode te ajudar agora.'}
      </StyledText>
      <StyledSingleInputForm
        buttonText={buttonText || 'Fale com um especialista'}
        type={type}
      />
    </Wrapper>
  );
}
