import styled from 'styled-components';
import { px2rem } from 'src/util';

export const StyledForm = styled.form`
  display: flex;
  max-width: 100%;
  position: relative;

  @media screen and (min-width: 998px) {
    height: ${px2rem(65)};
    width: ${px2rem(800)};
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
  }
`;

export const StyledInput = styled.input`
  align-items: center;
  border: 1px solid #545c62;
  display: flex;
  font-size: ${px2rem(20)};
  justify-content: center;
  line-height: 115%;
  padding: 0 ${px2rem(25)};

  @media screen and (min-width: 998px) {
    border-radius: ${px2rem(65)} 0 0 ${px2rem(65)};
    border-right-color: transparent;
    flex-grow: 1;
    height: 100%;
  }

  @media screen and (max-width: 997px) {
    border-radius: 52px;
    height: 52px;
    margin-bottom: 16px;
  }
`;

export const ErrorMessage = styled.span`
  color: #f00;
  font-size: ${px2rem(14)};
  left: ${px2rem(20)};
  position: absolute;
  top: calc(100% + ${px2rem(5)});
`;

export const SendButton = styled.button`
  align-items: center;
  background-color: #21aa91;
  color: #fff;
  display: flex;
  font: 700 ${px2rem(20)} / 115% 'Univia Pro', 'Arial', 'sans-serif';
  justify-content: center;

  @media screen and (min-width: 998px) {
    border-radius: 0 ${px2rem(65)} ${px2rem(65)} 0;
    height: 100%;
    padding: 0 ${px2rem(26)};
    white-space: nowrap;
  }

  @media screen and (max-width: 997px) {
    border-radius: 52px;
    height: 52px;
    padding: 0 15px;
  }
`;

const SendMessage = styled(ErrorMessage)`
  @media screen and (min-width: 998px) {
    left: auto;
    right: ${px2rem(20)};
  }

  @media screen and (max-width: 997px) {
    top: calc(100% + 30px);
  }
`;

export const SendErrorMessage = styled(SendMessage)`
  color: #f00;
`;

export const SuccessMessage = styled(SendMessage)`
  color: #0a0;
`;
