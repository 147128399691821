import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Grid = styled.div`
  height: 100%;
  width: 100%;

  @media screen and (min-width: 998px) {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 997px) {
  }
`;

export default function StyledGrid({
  children,
  className = undefined,
  ...props
}: any) {
  return (
    <Grid className={classNames('grid', className)} {...props}>
      {children}
    </Grid>
  );
}
