import styled from 'styled-components';
import { Text, Title, SingleInputForm } from 'components';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  align-items: center;
  background-color: #545c62;
  color: #fff;
  display: flex;
  flex-direction: column;

  ${Title}, ${Text} {
    color: #fff;
    text-align: center;
  }

  ${Text} {
    font-family: 'Univia Pro', 'Arial', 'sans-serif';
    letter-spacing: -0.045em;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(114)} ${px2rem(40)};

    ${Title} {
      max-width: ${px2rem(565)};
    }

    ${Text} {
      max-width: ${px2rem(685)};
    }
  }

  @media screen and (max-width: 997px) {
    padding: 108px 20px;

    ${Title}, ${Text} {
      padding: 0 25px;
    }

    ${Title} {
      font-size: 32px;
    }

    ${Text} {
    }
  }
`;

export const StyledText = styled(Text)`
  margin: ${px2rem(50)} 0 ${px2rem(80)};
`;

export const StyledSingleInputForm = styled(SingleInputForm)`
  input {
    border-color: #ced4da;
  }

  @media screen and (min-width: 998px) {
    width: ${px2rem(788)};

    input {
      border-right-color: transparent;
    }
  }

  @media screen and (max-width: 997px) {
  }
`;
