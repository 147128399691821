export function px2rem(size: number, context: number = 16) {
  return size / context + 'rem';
}

export function px2vw(size: number, context: number = 1920) {
  return (size / context) * 100 + 'vw';
}

export function handleFirstTab(e: KeyboardEvent) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');

    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

export function handleMouseDownOnce() {
  document.body.classList.remove('user-is-tabbing');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

export function handleMouseMove(e: MouseEvent) {
  const rotatingElements = document.querySelectorAll(
    '.rotating-element'
  ) as NodeListOf<HTMLDivElement>;

  const translateX =
    ((e.clientX - window.innerWidth / 2) / window.innerWidth) * 30;

  const translateY =
    ((e.clientY - window.innerHeight / 2) / window.innerHeight) * 30;

  for (let i = 0; i < rotatingElements.length; i++) {
    rotatingElements[i].style.setProperty('--translateX', px2rem(translateX));
    rotatingElements[i].style.setProperty('--translateY', px2rem(translateY));
  }
}
