import styled from 'styled-components';
import { px2rem } from 'src/util';

export default styled.p`
  line-height: 150%;

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(24)};
  }

  @media screen and (max-width: 997px) {
    font-size: 20px;
  }
`;
