import styled from 'styled-components';
import { px2rem } from 'src/util';

export const StyledHeader = styled.header<any>`
  height: ${px2rem(90)};
  transform: translate3d(0, 0, 0);
  width: 100%;

  ${({ active }) =>
    active &&
    `
        left: 0;
        position: fixed;
        top: 0;
      `}
`;

export const Wrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
`;

export const StyledGrid = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
  }

  @media screen and (max-width: 997px) {
    height: 100%;
    padding: 0 20px;
  }
`;

export const Logo = styled.h1`
  a {
    display: block;
  }

  @media screen and (min-width: 998px) {
    height: ${px2rem(66)};

    a,
    img {
      height: 100%;
    }
  }

  @media screen and (max-width: 997px) {
    width: 268px;

    a,
    img {
      width: 100%;
    }
  }
`;

export const StyledNav = styled.nav<any>`
  @media screen and (min-width: 998px) {
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 997px) {
    backdrop-filter: blur(${px2rem(32)});
    background: rgba(255, 255, 255, 0.75);
    height: calc(100vh - 90px);
    left: 0;
    overflow-y: auto;
    padding-bottom: 90px;
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    transition: transform 0.3s;
    width: 100%;
    z-index: -1;

    ${({ active }) =>
      active &&
      `
        transform: translateY(0);
      `}
  }
`;

export const MenuList = styled.ul`
  display: flex;

  @media screen and (min-width: 998px) {
    align-items: center;
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
  }
`;

export const MenuItem = styled.li`
  display: flex;

  @media screen and (min-width: 998px) {
    &:not(:last-of-type) {
      margin-right: ${px2rem(80)};
    }
  }

  @media screen and (max-width: 997px) {
    border-bottom: 1px solid #dee2e6;
    flex-direction: column;
  }
`;

export const MenuTriangle = styled.img<any>`
  margin-left: ${px2rem(21)};
  pointer-events: none;
  transform: rotateZ(0);
  transition: transform 0.3s;

  ${({ active }) =>
    active &&
    `
      transform: rotateZ(-180deg);
    `}
`;

export const MenuLink = styled.button<any>`
  align-items: center;
  display: flex;
  font: ${px2rem(20)} / 150% 'Univia Pro', 'Arial', 'sans-serif';
  position: relative;
  transition: color 0.3s, font-weight 0.3s;

  &.active {
    color: #005370;

    &::after {
      opacity: 1;
    }
  }

  @media screen and (min-width: 998px) {
    padding: 0 ${px2rem(5)};
    white-space: nowrap;

    &::after {
      background-color: #005370;
      border-radius: ${px2rem(4)};
      content: '';
      display: block;
      height: ${px2rem(4)};
      left: 0;
      opacity: 0;
      position: absolute;
      top: 100%;
      transition: opacity 0.3s;
      width: 100%;
    }

    &:hover {
      color: #005370;

      &::after {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 997px) {
    padding: 24px 48px;
  }
`;

export const Submenu = styled.div<any>`
  @media screen and (min-width: 998px) {
    backdrop-filter: blur(${px2rem(32)});
    background: rgba(255, 255, 255, 0.75);
    height: ${px2rem(230)};
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    transition: opacity 0.3s, transform 0.3s;
    width: 100%;
    z-index: -1;

    ${({ active }) =>
      active &&
      `
      opacity: 1;
      transform: translateY(0);
    `}

    > div {
      height: 100%;
    }
  }

  @media screen and (max-width: 997px) {
  }
`;

export const SubmenuGrid = styled.div`
  height: 100%;
`;

export const SubmenuContent = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  max-width: ${px2rem(650)};

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
  }

  @media screen and (max-width: 997px) {
    margin-right: auto;
    padding-bottom: 38px;
    padding-left: 48px;
  }
`;

export const SubmenuTitle = styled.h2`
  color: #005370;
  font: 700 ${px2rem(32)} 'Univia Pro', 'Arial', 'sans-serif';
  margin-right: ${px2rem(23)};
  max-width: ${px2rem(135)};
  text-align: right;
`;

export const SubmenuLinks = styled.div`
  align-items: flex-start;
  border-left: 1px solid #bac4cc;
  display: flex;
  flex-direction: column;
  height: 86%;
  justify-content: center;

  ${MenuLink} {
    font-family: 'Inter', 'Arial', 'sans-serif';
    font-size: ${px2rem(18)};
  }

  @media screen and (min-width: 998px) {
    padding-left: ${px2rem(25)};

    ${MenuLink} {
      color: #005370;

      &:not(:last-of-type) {
        margin-bottom: ${px2rem(33)};
      }
    }
  }

  @media screen and (max-width: 997px) {
    border-left: 3px solid #005370;

    ${MenuLink} {
      padding: 20px 25px;
    }
  }
`;

export const MobileMenuButton = styled.button`
  margin-right: -20px;
  padding: 20px;

  img {
    pointer-events: none;
  }
`;
