import React from 'react';
import classNames from 'classnames';
import { CustomVisibilitySensor, StyledGrid, Title } from 'components';
import {
  Wrapper,
  BackgroundImage,
  MapPins,
  Content,
  StyledText
} from './styles';

export default function Map() {
  return (
    <CustomVisibilitySensor offset={{ top: -300, bottom: -300 }}>
      {({ isVisible }) => (
        <Wrapper>
          <BackgroundImage src={require('assets/mapa.jpg')} alt="Mapa" />
          <MapPins
            className={classNames({ isVisible })}
            src={require('assets/map-pins.svg')}
          />
          <StyledGrid>
            <Content>
              <Title>Presença Nexodata</Title>
              <StyledText>
                Geramos milhões de prescrições em todos os estados brasileiros e
                conectamos nossos parceiros à rede Nexodata, composta por
                milhares de farmácias espalhadas em mais de 3.000 municípios.
              </StyledText>
            </Content>
          </StyledGrid>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
