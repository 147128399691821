module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00e3d4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nexodata","short_name":"Nexodata","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/assets/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"20492cdb98fe70c4963a05e81b557f01"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
