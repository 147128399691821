import React, {
  HTMLProps,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { Link } from 'gatsby';
import { Link as ScrollLink, ScrollLinkProps } from 'react-scroll';
import { useLocation } from '@reach/router';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import ScrollLock from 'react-scrolllock';
import {
  Wrapper,
  StyledHeader,
  StyledGrid,
  Content,
  Logo,
  StyledNav,
  MenuList,
  MenuItem,
  MenuLink,
  MenuTriangle,
  Submenu,
  SubmenuGrid,
  SubmenuContent,
  SubmenuTitle,
  SubmenuLinks,
  MobileMenuButton
} from './styles';

const scrollLinkDefaultProps: Partial<LinkProps> = {
  duration: 400,
  smooth: true,
  spy: true,
  onKeyDown: (e: any) => {
    if (e.keyCode === 13) {
      (e.target as HTMLAnchorElement).click();
    }
  },
  tabIndex: 0
};

type LinkProps = ScrollLinkProps<HTMLProps<HTMLButtonElement>>;

const FilteredLink = ({ active, ...props }: any) => <Link {...props} />;

export default function Header() {
  const [offset, setOffset] = useState(-90);
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const timeout = useRef<number>(0);
  const openLink = useRef<HTMLButtonElement>();
  const menuButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (
      (window.innerWidth <= 997 && window.scrollY >= 90) ||
      (window.innerWidth > 997 && window.scrollY >= 66)
    ) {
      if (!active) {
        setActive(true);
      }
    } else if (active) {
      setActive(false);
    }
  }, [active]);

  useEffect(() => {
    if (window.innerWidth <= 997) {
      setOffset(-81);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const checkPathName = useCallback(
    regex => {
      return regex.test(location.pathname);
    },
    [location.pathname]
  );

  function toggleSubmenu() {
    setSubmenuOpen(state => !state);
  }

  const closeSubmenu = useCallback(
    (e = undefined) => {
      if (submenuOpen && e?.target !== openLink.current) {
        setSubmenuOpen(false);
      }
    },
    [submenuOpen]
  );

  function toggleMenu() {
    setMenuOpen(state => !state);
  }

  const closeMenu = useCallback(
    (e = undefined) => {
      if (menuOpen && e?.target !== menuButton.current) {
        setMenuOpen(false);
      }
    },
    [menuOpen]
  );

  function handleSubmenuLinkClick() {
    closeMenu();
    closeSubmenu();
  }

  return (
    <StyledHeader active={active}>
      <Wrapper>
        <StyledGrid className="grid">
          <Content>
            <Logo>
              <Link to="/">
                <img alt="Nexodata" src={require('assets/logo-topo.svg')} />
              </Link>
            </Logo>
            <MobileMenuButton
              className="mobile"
              onClick={toggleMenu}
              ref={menuButton}
            >
              <img src={require('assets/menu.svg')} alt="Menu" />
            </MobileMenuButton>
            <ScrollLock isActive={menuOpen}>
              <StyledNav active={menuOpen}>
                <OutsideClickHandler onOutsideClick={closeMenu}>
                  <MenuList>
                    <MenuItem>
                      <MenuLink
                        className={classNames({
                          active: checkPathName(
                            /\/(atrium)|(farmacias)|(receitas)\/?/
                          )
                        })}
                        onClick={toggleSubmenu}
                        ref={openLink}
                      >
                        Soluções
                        <MenuTriangle
                          active={submenuOpen}
                          alt=""
                          className="desktop"
                          src={require('assets/menu-triangle.svg')}
                        />
                      </MenuLink>
                      <Submenu active={submenuOpen}>
                        <OutsideClickHandler onOutsideClick={closeSubmenu}>
                          <SubmenuGrid className="grid">
                            <SubmenuContent>
                              <SubmenuTitle className="desktop">
                                Conheça nossas soluções
                              </SubmenuTitle>
                              <SubmenuLinks>
                                <MenuLink
                                  className={classNames({
                                    active:
                                      checkPathName(/\/receitas\/?/) &&
                                      location.search === '?from=1'
                                  })}
                                  as={({ active, ...props }: any) => (
                                    <Link {...props} />
                                  )}
                                  onClick={handleSubmenuLinkClick}
                                  title="Para Prontuários Eletrônicos, Telemedicina e HIS"
                                  to="/receitas?from=1"
                                >
                                  Para Prontuários Eletrônicos, Telemedicina e
                                  HIS
                                </MenuLink>
                                <MenuLink
                                  className={classNames({
                                    active:
                                      checkPathName(/\/receitas\/?/) &&
                                      location.search !== '?from=1'
                                  })}
                                  as={({ active, ...props }: any) => (
                                    <Link {...props} />
                                  )}
                                  onClick={handleSubmenuLinkClick}
                                  title="Para Instituições de Saúde"
                                  to="/receitas"
                                >
                                  Para Instituições de Saúde
                                </MenuLink>
                                <MenuLink
                                  className={classNames({
                                    active: checkPathName(/\/farmacias\/?/)
                                  })}
                                  as={({ active, ...props }: any) => (
                                    <Link {...props} />
                                  )}
                                  onClick={handleSubmenuLinkClick}
                                  title="Para Farmácias"
                                  to="/farmacias"
                                >
                                  Para Farmácias
                                </MenuLink>
                              </SubmenuLinks>
                            </SubmenuContent>
                          </SubmenuGrid>
                        </OutsideClickHandler>
                      </Submenu>
                    </MenuItem>
                    <MenuItem>
                      <MenuLink
                        as={FilteredLink}
                        className={classNames({
                          active: checkPathName(/\/sobre\/?/)
                        })}
                        onClick={closeMenu}
                        title="A Nexo"
                        to="/sobre"
                      >
                        A Nexo
                      </MenuLink>
                    </MenuItem>
                    <MenuItem>
                      <MenuLink
                        as="a"
                        href="https://nexodata.zendesk.com/hc/pt-br"
                        onClick={closeMenu}
                        rel="noopener noreferrer"
                        tabIndex={0}
                        target="_blank"
                        title="Ajuda"
                      >
                        Ajuda
                      </MenuLink>
                    </MenuItem>
                    <MenuItem>
                      <MenuLink
                        {...{ ...scrollLinkDefaultProps, offset }}
                        as={ScrollLink}
                        onClick={closeMenu}
                        spy={false}
                        to="footer"
                        title="Contatos"
                      >
                        Contatos
                      </MenuLink>
                    </MenuItem>
                  </MenuList>
                </OutsideClickHandler>
              </StyledNav>
            </ScrollLock>
          </Content>
        </StyledGrid>
      </Wrapper>
    </StyledHeader>
  );
}
