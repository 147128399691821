import React, { useState, useRef, useEffect, ImgHTMLAttributes } from 'react';
import classNames from 'classnames';
import { unmount } from 'react-scroll/modules/mixins/scroller';

interface OwnProps {
  innerRef?: any;
  styleProps?: object;
}

type Props = ImgHTMLAttributes<HTMLImageElement> & OwnProps;

export default function LoadImage({
  alt,
  className,
  innerRef,
  styleProps,
  ...props
}: Props) {
  const [loaded, setLoaded] = useState(true);
  const unMounted = useRef(false);

  useEffect(() => {
    return () => {
      unMounted.current = true;
    };
  }, []); //eslint-disable-line

  return (
    <img
      {...props}
      alt={alt}
      className={classNames('load-image', className, { loaded })}
      onLoad={() => {
        if (!unMounted.current) {
          setLoaded(true);
        }
      }}
      ref={innerRef}
    />
  );
}
