import React, { useEffect, useRef, MutableRefObject, useState } from 'react';
import { createPortal } from 'react-dom';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Background,
  Wrapper,
  Grid,
  ContentWrapper,
  ScrollableWrapper,
  Content,
  CloseButton,
  StyledTitle,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  MedicineTypes,
  Examples,
  Labels,
  Label,
  Type,
  CircleIcon,
  MobileCards,
  Card,
  CardRow,
  CardItem,
  CardItemTitle,
  PS
} from './styles';

const data = [
  {
    medicineTypes: 'Medicamentos Isentos de Prescrição (MIP) / OTC',
    examples: 'Tylenol, Novalgina, Luftal',
    labels: ['blue'],
    prescriptionType: 'Não precisa de receita',
    signatureType: '-',
    allowsElectronicPrescription: true
  },
  {
    medicineTypes: 'Medicamentos sob Prescrição Médica',
    examples: 'Omeprazol, Glifage, Xarelto',
    labels: ['red'],
    prescriptionType: 'Branca comum',
    signatureType:
      'Assinatura Digital com certificado ICP-Brasil; ou Cópia digitalizada da receita com assinatura física*',
    allowsElectronicPrescription: true
  },
  {
    medicineTypes: 'Medicamentos Antibióticos Controlados',
    examples: 'Amoxil, Astro, Tamiram',
    labels: ['red'],
    prescriptionType: 'Branca Comum (2 vias)',
    signatureType: 'Assinatura Digital com certificado ICP-Brasil',
    allowsElectronicPrescription: true
  },
  {
    medicineTypes:
      'Medicamentos Controlados pela Portaria 344. Listas C1 e C5 e adendos das listas A1, A2 e B1',
    examples: 'Gardenal, Stabil, Assert',
    labels: ['red'],
    prescriptionType: 'Receita de Controle Especial',
    signatureType: 'Assinatura Digital com certificado ICP-Brasil',
    allowsElectronicPrescription: true
  },
  {
    medicineTypes:
      'Medicamentos Controlados pela Portaria 344. Listas A1, A2, C2 e C3  (tarja vermelha) e A3, B1, B2 (tarja preta)',
    examples: 'Síbus, Roacutan, Ritalina',
    labels: ['red', 'black'],
    prescriptionType: 'Branca Comum (2 vias)',
    signatureType: 'Assinatura Digital com certificado ICP-Brasil',
    allowsElectronicPrescription: false
  }
];

function renderLabel(label: string, index: number) {
  switch (label) {
    case 'blue':
      return (
        <Label
          alt="Tarja azul"
          key={index}
          src={require('assets/tarja-azul.svg')}
        />
      );
    case 'red':
      return (
        <Label
          alt="Tarja vermelha"
          key={index}
          src={require('assets/tarja-vermelha.svg')}
        />
      );
    case 'black':
      return (
        <Label
          alt="Tarja preta"
          key={index}
          src={require('assets/tarja-preta.svg')}
        />
      );
  }
}

function renderCircleIcon(type: boolean) {
  if (type) {
    return <CircleIcon src={require('assets/check-circle.svg')} alt="Sim" />;
  } else {
    return <CircleIcon src={require('assets/x-circle.svg')} alt="Não" />;
  }
}

interface Props {
  linkRef: MutableRefObject<HTMLButtonElement>;
  onClose: any;
}

export default function Popup({ linkRef, onClose }: Props) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    closeButtonRef.current.focus();
    setActive(true);
    return () => {
      if (linkRef?.current) {
        linkRef.current.focus();
      }
    };
  }, []);

  function handleClose() {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }

  if (typeof document === undefined) {
    return null;
  }

  return createPortal(
    <Background>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <ScrollLock>
          <Wrapper active={active}>
            <Grid className="grid">
              <ContentWrapper>
                <CloseButton onClick={handleClose} ref={closeButtonRef}>
                  Fechar
                  <img
                    alt=""
                    className="desktop"
                    src={require('assets/close.svg')}
                  />
                  <img
                    alt=""
                    className="mobile"
                    src={require('assets/white-close.svg')}
                  />
                </CloseButton>
                <TouchScrollable>
                  <ScrollableWrapper>
                    <Content>
                      <StyledTitle>
                        Medicamentos que podem ser dispensados com uma receita
                        digital Nexodata
                      </StyledTitle>
                      <Table className="desktop">
                        <TableHeader>
                          <TableRow>
                            <TableHead>Tipos de Medicamentos</TableHead>
                            <TableHead>Tarja</TableHead>
                            <TableHead>Tipo de Receita</TableHead>
                            <TableHead>Tipo de Assinatura</TableHead>
                            <TableHead>Permite Prescrição Eletrônica</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data.map(
                            (
                              {
                                medicineTypes,
                                examples,
                                labels,
                                prescriptionType,
                                signatureType,
                                allowsElectronicPrescription
                              },
                              key
                            ) => (
                              <TableRow key={key}>
                                <TableData>
                                  <MedicineTypes>{medicineTypes}</MedicineTypes>
                                  <Examples>{examples}</Examples>
                                </TableData>
                                <TableData>
                                  <Labels>
                                    {labels.map((label, index) =>
                                      renderLabel(label, index)
                                    )}
                                  </Labels>
                                </TableData>
                                <TableData>
                                  <Type>{prescriptionType}</Type>
                                </TableData>
                                <TableData>
                                  <Type>{signatureType}</Type>
                                </TableData>
                                <TableData>
                                  {renderCircleIcon(
                                    allowsElectronicPrescription
                                  )}
                                </TableData>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                      <MobileCards className="mobile">
                        {data.map(
                          ({
                            medicineTypes,
                            examples,
                            labels,
                            prescriptionType,
                            signatureType,
                            allowsElectronicPrescription
                          }) => (
                            <Card>
                              <CardRow>
                                <CardItem>
                                  <CardItemTitle>
                                    Tipos de Medicamentos:
                                  </CardItemTitle>
                                  <MedicineTypes>{medicineTypes}</MedicineTypes>
                                  <Examples>{examples}</Examples>
                                </CardItem>
                              </CardRow>
                              <CardRow>
                                <CardItem>
                                  <CardItemTitle>Tarja:</CardItemTitle>
                                  <Labels>
                                    {labels.map((label, index) =>
                                      renderLabel(label, index)
                                    )}
                                  </Labels>
                                </CardItem>
                                <CardItem>
                                  <CardItemTitle>
                                    Tipo de Receita:
                                  </CardItemTitle>
                                  <Type>{prescriptionType}</Type>
                                </CardItem>
                              </CardRow>
                              <CardRow>
                                <CardItem>
                                  <CardItemTitle>
                                    Tipo de Assinatura:
                                  </CardItemTitle>
                                  <Type>{signatureType}</Type>
                                </CardItem>
                              </CardRow>
                              <CardRow>
                                <CardItemTitle>
                                  Permite Prescrição Eletrônica:
                                </CardItemTitle>
                                {renderCircleIcon(allowsElectronicPrescription)}
                              </CardRow>
                            </Card>
                          )
                        )}
                      </MobileCards>
                      <PS>
                        *O CFF entende que a receita digitalizada é considerada
                        válida para essa categoria de medicamentos e para os
                        MIPs, por meio de cópia legível encaminhada por meios
                        eletrônicos.
                      </PS>
                    </Content>
                  </ScrollableWrapper>
                </TouchScrollable>
              </ContentWrapper>
            </Grid>
          </Wrapper>
        </ScrollLock>
      </OutsideClickHandler>
    </Background>,
    document.querySelector('#portal')
  );
}
