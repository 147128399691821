import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TopBar, Header, Footer } from 'components';
import { px2rem, handleFirstTab } from 'src/util';
import 'src/styles/styles.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const TopWrapper = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const Main = styled.main`
  position: relative;
  z-index: 0;

  @media screen and (min-width: 998px) {
    padding-top: ${px2rem(156)};
  }

  @media screen and (max-width: 997px) {
    padding-top: 180px;
  }
`;

const Portal = styled.div`
  position: relative;
  z-index: 1;
`;

export default function Layout({ children }: LayoutProps) {
  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab);
    return () => {
      window.removeEventListener('keydown', handleFirstTab);
    };
  }, []);

  return (
    <>
      <TopWrapper>
        <TopBar />
        <Header />
      </TopWrapper>
      <Main>
        {children}
        <Footer />
      </Main>
      <Portal id="portal" />
    </>
  );
}
