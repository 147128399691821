import React, { useState, useEffect, useRef } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import axios from 'axios';
import api from 'src/axios';
import validationSchema from './validationSchema';
import {
  StyledForm,
  StyledInput,
  ErrorMessage,
  SendButton,
  SuccessMessage,
  SendErrorMessage
} from './styles';

interface Values {
  email: string;
}

interface FormProps {
  buttonText: string;
  type: string;
}

export default function SingleInputForm({
  buttonText,
  type,
  ...props
}: FormProps) {
  const [error, setError] = useState(null);
  const request = useRef(axios.CancelToken.source());

  const formik = useFormik<Values>({
    initialValues: { email: '' },
    onSubmit: (
      { email }: Values,
      { resetForm, setStatus }: FormikHelpers<Values>
    ) => {
      const send = async () => {
        setError(null);
        setStatus('loading');
        const {
          data: { message, success }
        } = await api.post(
          '/mail',
          { email, type },
          { cancelToken: request.current.token }
        );
        if (!success) {
          setStatus('error');
          setError(message);
        } else {
          resetForm();
          setStatus('success');
          setTimeout(() => {
            setStatus('');
          }, 10000);
        }
      };
      send();
    },
    validationSchema,
    validateOnMount: true
  });

  useEffect(() => {
    return () => {
      if (request.current) {
        request.current.cancel();
      }
    };
  }, []);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    status
  } = formik;

  return (
    <StyledForm {...props} method="POST" onSubmit={handleSubmit}>
      <StyledInput
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Insira aqui seu e-mail"
        type="email"
        value={values.email}
      />
      <SendButton disabled={status === 'loading' || !isValid} type="submit">
        {buttonText}
      </SendButton>
      {touched.email && errors.email && (
        <ErrorMessage>{errors.email}</ErrorMessage>
      )}
      {status === 'success' && (
        <SuccessMessage>Entraremos em contato</SuccessMessage>
      )}
      {status === 'error' && <SendErrorMessage>{error}</SendErrorMessage>}
    </StyledForm>
  );
}
