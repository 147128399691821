import styled from 'styled-components';
import { px2rem } from 'src/util';

export const Wrapper = styled.footer`
  background-color: #2e3740;
  color: #fff;

  @media screen and (min-width: 998px) {
    padding: ${px2rem(50)} 0;
  }

  @media screen and (max-width: 997px) {
    padding: 50px 20px 80px;
  }
`;

export const Content = styled.div`
  h2 {
    font-size: ${px2rem(18)};
    margin-bottom: ${px2rem(14)};
  }

  p {
    font-size: ${px2rem(18)};
  }

  small {
    display: block;
    font-size: ${px2rem(12)};

    span {
      display: inline-block;
    }
  }

  .social-networks {
    align-items: center;
    display: flex;

    a {
      padding: ${px2rem(8)};
    }
  }

  @media screen and (min-width: 1201px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1200px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
    > img {
      margin-bottom: ${px2rem(65)};
    }

    .social-networks {
      margin-left: auto;
    }

    small {
      margin-top: ${px2rem(60)};
    }
  }

  @media screen and (max-width: 997px) {
    > img {
      margin-bottom: 40px;
    }

    .social-networks {
      margin-left: -8px;
      margin-top: 35px;
    }

    small {
      margin-top: 50px;
    }
  }
`;

export const Addresses = styled.div`
  > div {
    display: flex;
  }

  @media screen and (min-width: 998px) {
    margin-bottom: ${px2rem(50)};

    > div {
      align-items: center;
    }

    p:not(:last-of-type) {
      margin-right: ${px2rem(96)};
    }
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 45px;

    > div {
      align-items: flex-start;
      flex-direction: column;
    }

    p:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const Contact = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    margin-top: ${px2rem(10)};

    > a {
      align-items: center;
      display: flex;
      font-size: ${px2rem(18)};
      font-weight: 700;

      img {
        margin-right: ${px2rem(16)};
      }
    }
  }

  @media screen and (min-width: 998px) {
    a:hover {
      text-decoration: underline;
    }

    div {
      align-items: center;

      > a {
        margin-left: ${px2rem(6)};
      }
    }
  }

  @media screen and (max-width: 997px) {
    div {
      flex-direction: column;

      > a {
        margin-top: ${px2rem(30)};
      }
    }
  }
`;
