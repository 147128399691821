import styled from 'styled-components';
import { InlineSVG, LoadImage, Text } from 'components';
import { px2rem } from 'src/util';

function pinsStyle() {
  let style = '';

  for (let i = 1; i <= 25; i++) {
    const randomValue = Math.random();

    style += `
      &:nth-of-type(${i}) {
        transition: opacity 0.3s ${randomValue}s, transform 0.3s ${randomValue}s; 
      }
    `;
  }

  return style;
}

const height = 977;

const mobileHeight = 628;

export const Wrapper = styled.div`
  position: relative;

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(height / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    height: ${mobileHeight}px;
    margin-bottom: 176px;
  }
`;

export const BackgroundImage = styled(LoadImage)`
  right: 0;
  position: absolute;
  top: 0;
  height: 100%;
`;

export const MapPins = styled(InlineSVG)`
  display: block;
  position: absolute;

  svg {
    height: 100%;
    overflow: visible;
    width: 100%;

    #map-pins > g {
      opacity: 0;
      transform: translateY(-20%);

      ${pinsStyle()}
    }
  }

  &.isVisible svg #map-pins > g {
    opacity: 1;
    transform: translateY(0);
  }

  @media screen and (min-width: 998px) {
    bottom: ${(22 / height) * 100}%;
    width: ${(669 / 1920) * 100}%;
    right: ${(90 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    bottom: ${(22 / height) * mobileHeight}px;
    width: ${(669 / 1920) * 1234}px;
    right: ${(90 / 1920) * 1234}px;
  }
`;

export const Content = styled.div`
  align-self: center;
  backdrop-filter: blur(${px2rem(32)});
  background: rgba(255, 255, 255, 0.5);
  border-radius: ${px2rem(3)};
  margin-right: auto;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
    padding: ${px2rem(70)};
  }

  @media screen and (max-width: 997px) {
    bottom: -176px;
    padding: 55px 20px;
    position: absolute;
    width: 100%;
  }
`;

export const StyledText = styled(Text)`
  margin-top: ${px2rem(30)};

  @media screen and (min-width: 998px) {
    max-width: ${px2rem(575)};
  }
`;
