import React from 'react';
import styled from 'styled-components';
import { px2rem } from 'src/util';

export const TOPBAR_DESKTOP_HEIGHT = 66;

const StyledLink = styled.a`
  align-items: center;
  background: #fff0de;
  color: #131313;
  display: flex;
  position: relative;
  width: 100%;
  z-index: 1;

  span {
    align-items: center;
    background: #000;
    border-radius: ${px2rem(20)};
    color: #fff;
    display: flex;
    height: ${px2rem(39)};
    white-space: nowrap;
  }

  @media screen and (min-width: 998px) {
    height: ${px2rem(TOPBAR_DESKTOP_HEIGHT)};
    justify-content: center;

    &:focus,
    &:hover {
      span {
        background-color: #404040;
      }
    }

    span {
      margin-left: ${px2rem(25)};
      padding: 0 ${px2rem(20)};
      transition: background-color 0.3s;
    }
  }

  @media screen and (max-width: 997px) {
    height: 90px;
    justify-content: center;
    padding: 0 10px;

    p {
      font-size: 16px;
    }

    span {
      margin-left: 17px;
      padding: 0 16px;
    }
  }
`;

export default function TopBar() {
  return (
    <StyledLink
      href="https://farmacias.nexodata.com.br"
      target="_blank"
      rel="noopener noreferrer"
      title="Acessar prescrição eletrônica"
    >
      <p>Você é farmacêutico e precisa dispensar uma prescrição eletrônica?</p>
      <span>Acesse aqui</span>
    </StyledLink>
  );
}
