import styled from 'styled-components';
import { Title } from 'components';
import { px2rem } from 'src/util';

export const Background = styled.section`
  background-color: rgba(18, 22, 25, 0.8);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  > div {
    cursor: initial;
    width: 100%;
  }

  @media screen and (min-width: 998px) {
    > div {
      height: 90%;
    }
  }

  @media screen and (max-width: 997px) {
    > div {
      height: calc(100% - 66px);
    }
  }
`;

export const Wrapper = styled.div<any>`
  background-color: #fff;
  height: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s, transform 0.3s;
  width: 100%;
  z-index: 1;

  ${({ active }) =>
    active &&
    `
      opacity: 1;
      transform: translateY(0);
    `}

  @media screen and (min-width: 998px) {
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${px2rem(80)} 0 ${px2rem(120)};
  }
`;

export const Grid = styled.div`
  @media screen and (max-width: 997px) {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (max-width: 997px) {
    height: 100%;
  }
`;

export const ScrollableWrapper = styled.div`
  @media screen and (max-width: 997px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }
`;

export const Content = styled.div`
  @media screen and (max-width: 997px) {
    padding: 50px 20px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: ${px2rem(48)};

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(32)};
    max-width: ${px2rem(610)};
  }

  @media screen and (max-width: 997px) {
    font-size: ${px2rem(32)};
  }
`;

export const CloseButton = styled.button`
  align-items: center;
  display: flex;
  font: 700 ${px2rem(16)} / 115% 'Univia Pro', 'Arial', 'sans-serif';
  position: absolute;
  right: 0;

  img {
    margin-left: ${px2rem(8)};
  }

  @media screen and (min-width: 998px) {
    color: #2e3740;
    top: 0;
  }

  @media screen and (max-width: 997px) {
    bottom: 100%;
    color: #fff;
    padding: 20px;
    position: fixed;
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  border-bottom: 3px solid #f1f3f5;
`;

export const TableHeader = styled.thead``;

export const TableRow = styled.tr`
  &:nth-of-type(2n) {
    background-color: #f1f3f5;
  }
`;

export const TableBody = styled.tbody``;

export const TableHead = styled.th`
  background-color: #f0f0f0;
  color: #00778f;
  font: ${px2rem(20)} / 150% 'Univia Pro', 'Arial', 'sans-serif';
  padding: ${px2rem(16)} ${px2rem(24)};
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &:nth-of-type(4) {
    width: ${px2rem(280)};
  }

  &:not(:last-of-type) {
    border-right: ${px2rem(8)} solid #fff;
  }

  &:not(:first-of-type) {
    border-left: ${px2rem(8)} solid #fff;
  }
`;

export const TableData = styled.td`
  font: ${px2rem(16)} / 150% 'Univia Pro', 'Arial', 'sans-serif';
  padding: ${px2rem(30)} ${px2rem(24)};
  vertical-align: middle;

  &:not(:last-of-type) {
    border-right: ${px2rem(8)} solid transparent;
  }

  &:not(:first-of-type) {
    border-left: ${px2rem(8)} solid transparent;
  }
`;

export const MedicineTypes = styled.p``;

export const Examples = styled.p`
  font-weight: 300;
`;

export const Labels = styled.div`
  display: flex;

  @media screen and (min-width: 998px) {
    flex-direction: column;
  }
`;

export const Label = styled.img`
  @media screen and (min-width: 998px) {
    margin: auto;

    &:not(:last-of-type) {
      margin-bottom: ${px2rem(8)};
    }
  }

  @media screen and (max-width: 997px) {
    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }
`;

export const Type = styled.p`
  @media screen and (min-width: 998px) {
    text-align: center;
  }
`;

export const CircleIcon = styled.img`
  margin: auto;

  @media screen and (max-width: 997px) {
    margin-left: 25px;
  }
`;

export const PS = styled.p`
  font-weight: 700;
  line-height: 150%;
  margin-top: ${px2rem(24)};
  max-width: ${px2rem(870)};

  @media screen and (max-width: 997px) {
    font-size: 14px;
  }
`;

export const MobileCards = styled.div``;

export const Card = styled.div`
  box-shadow: 10px 14px 44px rgba(0, 0, 0, 0.12);
  font: ${px2rem(14)} / 150% 'Univia Pro', 'Arial', 'sans-serif';
  padding: 24px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const CardRow = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

export const CardItemTitle = styled.h3`
  align-self: center;
  color: #00778f;
  font: 12px / 150% 'Univia Pro';
`;

export const CardItem = styled.div`
  &:not(:last-of-type) {
    margin-right: 32px;
  }

  ${CardItemTitle} {
    margin-bottom: 8px;
  }
`;
